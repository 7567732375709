.paperdivres{
    padding: 3px 3px;
    display: flex;
    width: 300;
  }
  
  .dividerinputres {
    height: 20;
    margin: 4;
  }
  
  .cardstyle {
    /* width: 40vmin; */
    /* height: 47vmin; */
    /* width: 250px; */
    /* height: 265px; */
    /* float: left; */
    /* margin: 5px 5px 5px 5px; */
  
    margin: 15px;
    width: 300px;
    height: 290px;
    box-shadow: 2px 2px 2px #888888!important;
    padding:0!important;
    float: left;
  }
  
  .cardheaderstyle {
    /* width: 30vmin; */
    /* height: 7vmin; */
    /* margin-top: 3px; */
    /* >>>>>>>>>> aggiunta */
  }
  
  .cardmediastyle {
    /* width: 40vmin; */
    width: auto;
    /* height: 27vmin; */
    height: 180px;
    /* margin-top: 10px;  */
    /* object-fit: cover !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important; */
  
  }
  
  .cardcontentstyle {
    width: 30vmin;
    height: 2vmin;
  }
  
  .cardsubtextstyle {
    font-size: 11px;
  }
  
  .cardtextstyle {
    /* width: 15vmin; */
    width: 140px;
    /* min-width: 15vmin; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .cardtextstylefooter {
    /* width: 30vmin; */
    /* min-width: 20vmin; */
    /* min-width: 16vmin; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .carddesc {
    width:35%;
  }
  
  .cardshare {
    width: 100%;
  }
  
  .actionarea {
    width: 400px;
    height: 40px;
  }
  .gridcontainerwithmap {
    width: 100vw;
    height: 100vh;
    /* margin: 30px 0px 15px 5px; */
    margin-top: 0px;
  }
  
  .gridcontainer {
    width: 100vw;
    height: 100vh;
    /* margin: 30px 5px 15px 5px; */
    /* padding: 1vh 0px 1vh 6%; */
    margin-top: 10px;
  }
  
  .cardcontainer {
    margin: 0px 1vmin 0px 1vmin;
  }
  
  .appbarstyle{
    margin: 0px 0px 0px 0px;
  }
  
  .mapcontainer{
    margin: 0px 0px 0px 0px;
    height: 80vh;
    width: 65vw;
    position: fixed;
    margin: 0;
  }
  
  
  .rights-img-RM {
    width: 62px;
    bottom: 1px;
    top:5px;
    position: relative;
  }
  
  
  .type-find {
    position: absolute;
    z-index: 1;
    padding-top: 20px;
    margin-left: 5px;
  }
  
  
  .public-rights-img {
    width: 32px;
    position: relative;
  }
  