.imagemediasemantic {
  height: 140px;
  width: 88%;
  margin-left: 18px;
  border-radius: 8px;
  /* padding-left: 50px; */
}

.contain {
  background-size: contain !important;
}

.dropdown{
  max-width: 370px;
  min-width: 270px;
  padding: 8px;
}

.dropdownINFO{
  max-width: 370px;
  min-width: 270px;
  padding: 8px;
}



.ellips-soc {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  padding-left: 50px;
}

.textDesc {
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.icon-10 {
  top: 7px;
  position: relative;
}

/*
.parent_div .child_div p {
display: inline-block;
} */

.centerInfo {
  flex-grow: 1;
  /* align-self: flex-end; */
  text-align: center;
}

.btn-dotted {
  border: 2px dotted;
  border-radius: 50px;
  background-color: #e8dada;
}
